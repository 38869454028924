<template>
    <v-row class="fill-height">
        <v-col>
            <v-row>
                <v-col md="12">
                    <v-card>
                        <v-card-title>
                            <v-icon
                                @click="pushRouteToView('products')"
                                class="mr-4"
                                style="cursor: pointer"
                            >
                                mdi-arrow-left
                            </v-icon>
                            <v-icon class="mr-3">mdi-account-group</v-icon>
                            {{ crudDetail }} cliente
                            <v-spacer></v-spacer>
                        </v-card-title>
                    </v-card>

                    <v-col md="12">
                    <v-form
                        v-model="editCreateProduct"
                        v-on:submit.prevent="submitCreateEditProd()"
                    >
                        <v-card>
                            <v-card-text>
                                <v-row>
                                    <v-col md="4" sm="12">
                                        <v-text-field
                                            v-model="name"
                                            outlined
                                            :rules="textRules"
                                            label="Nome"
                                        />
                                    </v-col>
                                    <v-col md="3" sm="12">
                                        <v-text-field
                                            v-model="cpf"
                                            outlined
                                            :rules="cpfRule"
                                            label="CPF"
                                            v-mask="['###.###.###-##']"
                                        />
                                    </v-col>
                                    <v-col md="3" sm="12">
                                        <v-text-field
                                            v-model="phone"
                                            outlined
                                            :rules="telRules"
                                            label="Telefone"
                                        />
                                    </v-col>
                                    <v-col md="2" sm="12">
                                        <v-text-field
                                            v-model="birthdate"
                                            outlined
                                            :rules="textRules"
                                            v-mask="['##/##/####']"
                                            label="Data de nascimento"
                                        />
                                    </v-col>
                                    <v-col md="12" class="text-right">
                                        <v-row>
                                            <v-col md="12">
                                                <v-btn
                                                    type="submit"
                                                    style="color: #fff; background-color: rgb(61, 17, 89)"
                                                >
                                                    <v-icon left>
                                                        mdi-check
                                                    </v-icon>
                                                    Enviar
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-form>
                </v-col>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
    name: 'EditCreateProduct',
    directives: { mask },
    data: () => ({
        codigo: '',
        classe: '',
        unidade: '',
        tipoEmbalagem: '',
        descricao: '',
        categoria: '',
        fornecedor: '',
        codBarras: '',
        refFabricante: '',
        unitEntrada: '',
        margem: '',
        unitSaida: '',
        estoqueMin: '',
        estoqueMax: '',
        comissionaBm: '',
        estoqueAtual: '',
        addCompra: '',
        unitCompra: '',
    }),
    methods: {
        submitCreateEditProd() {
            console.log('dddd');
        },
        pushRouteToView(route) {
            this.$router.push({ name: route })
        },
    }
}
</script>